@tailwind base;
@tailwind components;
@tailwind utilities;

body {
   background-color: #fff;
   ;
}
/* Public styles */
.accent-btn {
    @apply bg-gradient-to-br from-secondary-300 to-[#004FC4] text-white rounded-full;
}
.text-accent {
    @apply bg-gradient-to-br from-secondary-300 to-[#004FC4] text-transparent bg-clip-text;
}

.accent-text {
    @apply bg-gradient-to-br from-secondary-300 to-[#004FC4] text-white ;
}